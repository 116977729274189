import { useEffect, useState } from "react"

const Media = ({ query, children }) => {
  const [matches, setMatches] = useState()

  useEffect(() => {
    const media = window.matchMedia(query)

    media.addEventListener("change", update)

    return () => {
      media.removeEventListener("change", update)
    }
  })

  useEffect(() => {
    const media = window.matchMedia(query)
    setMatches(media.matches)
  }, [query])

  function update({ matches }) {
    setMatches(matches)
  }

  return children(matches)
}

export default Media
