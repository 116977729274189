// extracted by mini-css-extract-plugin
export var billetShadow = "services-list-module--billet-shadow--79605";
export var colorScheme__background__grayPale = "services-list-module--colorScheme__background__gray-pale--5069d";
export var common__list__mdashed = "services-list-module--common__list__mdashed--0f948";
export var icomoon = "services-list-module--icomoon--7b21f";
export var lowercase = "services-list-module--lowercase--d1f51";
export var prefaceText = "services-list-module--prefaceText--3a440";
export var servicesList = "services-list-module--servicesList--a4881";
export var servicesList__wrapper = "services-list-module--servicesList__wrapper--7ef09";
export var transition = "services-list-module--transition--e49b5";
export var transitionBackground = "services-list-module--transition-background--d7a34";
export var transitionReverse = "services-list-module--transition-reverse--798a4";
export var uppercase = "services-list-module--uppercase--bdf23";