// extracted by mini-css-extract-plugin
export var billetShadow = "colored-section-module--billet-shadow--e6e8a";
export var colorScheme__background__grayPale = "colored-section-module--colorScheme__background__gray-pale--993da";
export var colored = "colored-section-module--colored--1ece3";
export var extraBg = "colored-section-module--extraBg--5c134";
export var flexBlock = "colored-section-module--flexBlock--2bd79";
export var icomoon = "colored-section-module--icomoon--919a9";
export var img = "colored-section-module--img--66387";
export var lowercase = "colored-section-module--lowercase--96e06";
export var mt = "colored-section-module--mt--904d9";
export var strongText = "colored-section-module--strongText--73665";
export var strongTextBlock = "colored-section-module--strongTextBlock--5f6c1";
export var titleBlock = "colored-section-module--titleBlock--bb7b3";
export var transition = "colored-section-module--transition--2958c";
export var transitionBackground = "colored-section-module--transition-background--0db23";
export var transitionReverse = "colored-section-module--transition-reverse--26ac1";
export var uppercase = "colored-section-module--uppercase--bbad6";
export var withIndent = "colored-section-module--withIndent--e9509";