import React from "react"
import { Link } from "gatsby"

import "./_options-list.scss"

const OptionsList = ({ list, ordered, mobileOrdered, small }) => {
  const className = `${small ? " small" : ""}${ordered ? " ordered" : ""}${
    mobileOrdered ? " ordered mobile-ordered" : ""
  }`

  return (
    <ul className={`optionsList${className}`}>
      {list.map(option => (
        <li key={option.id}>
          {option.link ? (
            <Link to={option.link}>
              <h3>{option.title}</h3>
            </Link>
          ) : (
            <h3>{option.title}</h3>
          )}
          <p>{option.text}</p>
        </li>
      ))}
    </ul>
  )
}

export default OptionsList
