// extracted by mini-css-extract-plugin
export var accordionBlock = "product-design-module--accordionBlock--61d18";
export var accordionWrapper = "product-design-module--accordionWrapper--974c9";
export var billetShadow = "product-design-module--billet-shadow--4b7a5";
export var cardsBlock = "product-design-module--cardsBlock--af84a";
export var cardsWrapper = "product-design-module--cardsWrapper--44b08";
export var caseStudies = "product-design-module--caseStudies--b4775";
export var casesBlock = "product-design-module--casesBlock--a22a5";
export var colorScheme__background__grayPale = "product-design-module--colorScheme__background__gray-pale--1ff64";
export var flexBlock = "product-design-module--flexBlock--493cd";
export var headline = "product-design-module--headline--ded7d";
export var headline_grid = "product-design-module--headline_grid--96ee0";
export var icomoon = "product-design-module--icomoon--be598";
export var industries = "product-design-module--industries--8204d";
export var lowercase = "product-design-module--lowercase--06cad";
export var productDesignBlock = "product-design-module--productDesignBlock--b8abc";
export var strongText = "product-design-module--strongText--0ae52";
export var strongTextBlock = "product-design-module--strongTextBlock--fb60d";
export var transition = "product-design-module--transition--6c08e";
export var transitionBackground = "product-design-module--transition-background--60ada";
export var transitionReverse = "product-design-module--transition-reverse--f838d";
export var uppercase = "product-design-module--uppercase--e4b4a";
export var withIndent = "product-design-module--withIndent--7fe9d";