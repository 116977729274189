import React from "react"
import { IMG } from "../const"

export const OPTIONS_LIST = [
  {
    id: 1,
    title: "Startups",
    text: "From idea to the first interactive prototype.",
  },
  {
    id: 2,
    title: "Founders",
    text: "Increase the usability and usefulness of your digital product.",
  },
  {
    id: 3,
    title: "Head of IT dep",
    text: "Bring beauty and enjoyment into complex digital products.",
  },
  {
    id: 4,
    title: "Consulting",
    text: "Serve clients better with a rewarding product design.",
  },
]

export const SERVICES = {
  text: "",
  items: [
    {
      id: 1,
      title: "User Experience Design",
      text:
        "We focus on how users interact with products and use the best web design principles to create helpful user experiences. We also keep a focus on the balance among user needs, business goals, and technological feasibility.",
      link: "",
      image: "ux-design",
    },
    {
      id: 2,
      title: "Visual Design",
      text:
        "Professional design teams create and use the right pictures, typography, text, space, shapes, layout, and color to increase the aesthetic appeal, the usefulness of the product’s design, and emotional connection.",
      link: "",
      image: "visual-design",
    },
    {
      id: 3,
      title: "Design Strategy",
      text: (
        <p>
          Design strategy transforms strategic vision and objectives into
          feasible implementation phases. Ensure brand loyalty and raise client
          happiness by using a&nbsp;design strategy.
        </p>
      ),
      link: "",
      image: "design-strategy",
    },
    {
      id: 4,
      title: "UX Research",
      text: (
        <p>
          User experience (UX) research is the methodical examination of target
          users and their needs to provide design processes with relevant
          insights and understand the context. Unique design features and an
          intuitive understanding interface are rewards of deep
          UX&nbsp;research.
        </p>
      ),
      link: "",
      image: "ux-research",
    },
    {
      id: 5,
      title: "Design Audit",
      text: (
        <p>
          Our design leaders will provide you with a&nbsp;practical structure
          for the research focused on identifying issues and pain points that
          your consumers might face and share product improvement.
        </p>
      ),
      link: "",
      image: "design-audit",
    },
  ],
}

export const INDUSTRIES = [
  { id: 1, name: "business digitalization" },
  { id: 2, name: "sport tech" },
  { id: 3, name: "real estate" },
  { id: 4, name: "education tech" },
  { id: 5, name: "telecommunication" },
  { id: 6, name: "healthcare" },
  { id: 7, name: "data analytics & data visualization" },
  { id: 8, name: "recommendation system" },
  { id: 9, name: "hr & recruitment" },
  { id: 10, name: "finance tech" },
  { id: 11, name: "recommendation system" },
  { id: 12, name: "marketing tech" },
]

export const DESIGN_SERVICES = [
  {
    id: 1,
    title: "Ideas validation",
    text: "Quick hypothesis creation and validation with prototypes.",
  },
  {
    id: 2,
    title: "Improve conversions",
    text:
      "Lead generation and sales improvement as you learn what value to create and for who.",
  },
  {
    id: 3,
    title: "Development productivity increase",
    text:
      "Design solutions that include the information architecture, user flows, design system and interactive prototype are improving the quality of engineering team results.",
  },
  {
    id: 4,
    title: "Suitable approach",
    text:
      "We use the correct approach for your unique product to achieve the best results for your target audience.",
  },
  {
    id: 5,
    title: "Building a product from scratch",
    text:
      "Every new product should start with a prototype, test, and wireframe. This is the safest way to make a profitable product.",
  },
  {
    id: 6,
    title: "Dedicated design teams",
    text:
      "If your initial testing goes well, you will need a small team of designers and developers to build the digital product in higher fidelity. In that case, we offer work with our high-productive senior designer teams, who have a high level of English. LaSoft's dedicated design teams service is a great choice. It would be best to have an autonomous team of specialists like product managers, UX strategists, UI designers, and engineers for further product scaling or product redesign.",
  },
]

export const TITLES = {
  t_shape_designers: {
    title: (
      <>
        get an original <strong>product design</strong> that will speak for you
      </>
    ),
    strongText: "experience, help & insights",
    uppercase: "t–shape designers",
    withIndent: true,
  },
  services: {
    title: (
      <>
        <strong>product</strong> design services
      </>
    ),
    strongText: "we provide a wide range of product design services",
    uppercase: (
      <>
        A wide range of <br />
        services
      </>
    ),
    withIndent: false,
  },
  case_studies: {
    title: (
      <>
        case studies we're <strong>proud&nbsp;of</strong>
      </>
    ),
    strongText:
      "we are proud of the results of our work with clients so, we talk about them in the case study",
    uppercase: (
      <>
        cases from 10+
        <br /> industries services
      </>
    ),
    withIndent: true,
  },
  accordion: {
    title: (
      <>
        lasoft <strong>product design services</strong> are great for variety
        needs
      </>
    ),
    strongText:
      "we use a structured and tested approach to provide beautiful digital services for our partners",
    uppercase: "structured & tested",
    withIndent: true,
  },
  colored: {
    title: (
      <>
        interested in digital&nbsp;product <br />
        design?
      </>
    ),
    strongText:
      "request a consultation today so that we can help build your product",
    uppercase: "Project estimate",
    link: "#contact-form",
    linkClassName: "design-estimate",
    imageDesktop: `${IMG.PRODUCT_DESIGN}/scheme-white.svg`,
    imageDesktopWidth: 1208,
    imageDesktopHeight: 109,
    withIndent: false,
  },
}
