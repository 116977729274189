/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React from "react"
import cx from "classnames"

import * as styles from "./services-list.module.scss"
import ListItem from "./serviceListItem"

const ServicesList = ({ services, text }) => {
  return (
    <div className={cx(styles.servicesList__wrapper, "list-wrapper")}>
      {text && (
        <div className={styles.prefaceText}>
          {typeof text === "string" ? <p>{text}</p> : text}
        </div>
      )}
      <ul className={styles.servicesList}>
        {services.map(item => (
          <ListItem key={item.id} item={item} />
        ))}
      </ul>
    </div>
  )
}

export default ServicesList
