import React from "react"
import loadable from "@loadable/component"
import cx from "classnames"

import { IMG } from "../../const"
import * as styles from "./styles.module.scss"

const TitleBlock = loadable(() => import("../title-block"))

const TextAndScheme = ({ title }) => (
  <section className={cx("section", styles.secondBlock)}>
    <TitleBlock options={title} />
    <div className={styles.text}>
      <p>
        <strong>
          At LaSoft, we are proud to have a great team of T-shape designers. Our
          specialists have general entrepreneurship, psychology, finance, and
          coding knowledge.
        </strong>
      </p>
      <div>
        <p>
          Every one of our team members has unique strengths and deep knowledge
          of contextual inquiry and research or prototyping and wireframing,
          user flows and information architecture, and visual design.
        </p>
        <p>
          In addition to this, we have a professional design team who always
          collaborates on and reinforces each product.
        </p>
      </div>
    </div>
    <div className={styles.img}>
      <picture>
        <source
          media="(max-width: 768px)"
          srcSet={`${IMG.PRODUCT_DESIGN}/scheme-black-mob.svg`}
        />
        <source
          media="(min-width: 769px)"
          srcSet={`${IMG.PRODUCT_DESIGN}/scheme-black.svg`}
        />
        <img
          src={`${IMG.PRODUCT_DESIGN}/scheme-black.svg`}
          alt="Scheme"
          width="1208"
          height="111"
          loading="lazy"
        />
      </picture>
    </div>
  </section>
)

export default TextAndScheme
